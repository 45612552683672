import { useEffect, useState } from "react"
import { useToggle } from "react-use"
import { trpc } from "@/trpc/client"
import { Erc721Token } from "@/graphql/generated/apollo/graphql"
import toast from "react-hot-toast"
import { useFavoritesStore } from "@/stores/favorites"
import { useAccount } from "wagmi"
import { useProfileStore } from "@/stores/profile"
import { useConnectModal } from "@rainbow-me/rainbowkit"

export const useFavorites = (
  artwork: Pick<Erc721Token, "contract_address" | "token_id">,
  onComplete?: () => void
) => {
  const { isConnected } = useAccount()
  const { profile: account } = useProfileStore()
  const { openConnectModal: showDialog } = useConnectModal()
  const [isFavorite, setIsFavorite] = useState(false)
  const [isLoading, setIsLoading] = useToggle(false)
  const [likeCount, setLikeCount] = useState(0)
  const [isMutating, setIsMutating] = useToggle(false)
  const { isWaitingRemoval, setIsWaitingRemoval } = useFavoritesStore()

  const { data: likesData, isLoading: likesLoading } =
    trpc.profile.likesArtwork.useQuery(
      {
        address: account?.ethereum_address || "",
        contractAddress: artwork.contract_address || "",
        tokenId: artwork.token_id || 0,
      },
      {
        enabled:
          isConnected && !!account?.ethereum_address && !!artwork?.token_id,
      }
    )

  useEffect(() => {
    setIsLoading(likesLoading)
    if (likesData) {
      setIsFavorite(likesData?.get_user_like_for_nft?.date_created)
    }
  }, [likesData, likesLoading])

  const favoriteMutation = trpc.profile.favorite.useMutation({
    onSuccess: async () => {
      setIsFavorite((isFavorite) => !isFavorite)
      if (onComplete) {
        onComplete()
      }
      setIsMutating(false)
    },
  })

  const handleClickFavorite = async (action?: "remove" | "add") => {
    if (!isConnected || !account) {
      showDialog?.()
      return
    }

    setIsLoading(true)
    setIsMutating(true)

    try {
      let addToFavorites
      if (action === "add") {
        addToFavorites = true
      } else if (action === "remove") {
        addToFavorites = false
      } else {
        addToFavorites = !isFavorite
      }
      const response = await favoriteMutation.mutateAsync({
        address: account?.ethereum_address,
        contractAddress: artwork.contract_address,
        tokenId: artwork.token_id,
        addToFavorites: addToFavorites,
      })
      setIsFavorite(addToFavorites)
      toast.success(
        `Artwork ${addToFavorites ? "added to" : "removed from"} favorites`
      )
      setIsWaitingRemoval(
        addToFavorites
          ? isWaitingRemoval.filter((id) => id !== artwork.token_id)
          : [...isWaitingRemoval, artwork.token_id]
      )

      await new Promise((resolve) => setTimeout(resolve, 500))
      setLikeCount(
        addToFavorites
          ? response.create_nft_like.likeCount
          : response.delete_nft_like.likeCount
      )
    } catch (error) {
      toast.error("Failed to update favorite status.")
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    isMutating,
    isFavorite,
    likeCount,
    handleClickFavorite,
  }
}
