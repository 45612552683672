import React, { ReactNode } from "react"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { Button } from "./button"
import { cn } from "@/utils/cn"
import Link from "next/link"

type DropdownMenuContentProps = {
  children?: ReactNode
  sideOffset?: number
  alignOffset?: number
  contentClassName?: string
  align?: "start" | "end" | "center"
  side?: "top" | "bottom" | "left" | "right"
  rotateIcon?: boolean
  open?: boolean
  onOpenChange?: (open: boolean) => void
  small?: boolean
  smallMobile?: boolean
  [key: string]: any
}

export const DropdownMenuBtn = ({
  children,
  className,
  ...props
}: DropdownMenuContentProps & { className?: string }): JSX.Element => (
  <button className={cn("hover:underline", className)} {...props}>
    {children}
  </button>
)

export const DropdownMenuLink = ({
  children,
  className,
  href,
  ...props
}: DropdownMenuContentProps & {
  className?: string
  href: string
}): JSX.Element => (
  <Link href={href} className={cn("hover:underline", className)} {...props}>
    {children}
  </Link>
)

const DropdownMenuComponent = ({
  children,
  sideOffset = 8,
  alignOffset = 0,
  align = "start",
  side = "bottom",
  rotateIcon = false,
  contentClassName,
  open,
  small,
  smallMobile,
  onOpenChange,
}: DropdownMenuContentProps): JSX.Element => (
  <DropdownMenu.Root open={open} onOpenChange={onOpenChange}>
    <DropdownMenu.Trigger asChild>
      <Button
        kind="tertiarty"
        className={cn(
          "flex items-center justify-center !p-0",
          small && "!size-7 border-sr-border-tertiary",
          smallMobile &&
            `
              border-sr-border-tertiary
              mobile:!size-7
            `,
          !small && "!size-8"
        )}
      >
        <svg
          className={cn("dark:invert", rotateIcon && "rotate-90 transform")}
          width="12"
          height="4"
          viewBox="0 0 12 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 0.5C0.675 0.5 0 1.175 0 2C0 2.825 0.675 3.5 1.5 3.5C2.325 3.5 3 2.825 3 2C3 1.175 2.325 0.5 1.5 0.5ZM10.5 0.5C9.675 0.5 9 1.175 9 2C9 2.825 9.675 3.5 10.5 3.5C11.325 3.5 12 2.825 12 2C12 1.175 11.325 0.5 10.5 0.5ZM6 0.5C5.175 0.5 4.5 1.175 4.5 2C4.5 2.825 5.175 3.5 6 3.5C6.825 3.5 7.5 2.825 7.5 2C7.5 1.175 6.825 0.5 6 0.5Z"
            fill="black"
          />
        </svg>
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Portal>
      <DropdownMenu.Content
        className={cn(
          "rounded-md border border-sr-border-primary bg-sr-bg-primary py-5 pl-4 pr-[72px]",
          contentClassName
        )}
        sideOffset={sideOffset}
        alignOffset={alignOffset}
        align={align}
        side={side}
      >
        <nav className="flex flex-col">{children}</nav>
      </DropdownMenu.Content>
    </DropdownMenu.Portal>
  </DropdownMenu.Root>
)

const DropdownMenuComponentWrapper = Object.assign(DropdownMenuComponent, {
  Button: DropdownMenuBtn,
  Link: DropdownMenuLink,
})

export { DropdownMenuComponentWrapper as DropdownMenu }
