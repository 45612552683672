import { Address, getAddress, isAddressEqual } from "viem"

type MaybeAddress = string | Address | undefined

export const addressesAreEqual = (
  address1: MaybeAddress,
  address2: MaybeAddress
): boolean =>
  address1 && address2
    ? isAddressEqual(getAddress(address1), getAddress(address2))
    : false
