import React, { useEffect, useState } from "react"
import moment, { Moment } from "moment"
import { cn } from "@/utils/cn"

interface CountdownTimerProps {
  timestamp: number // Timestamp in milliseconds
  className?: string
}

interface Countdown {
  days: number
  hours: number
  minutes: number
}

const Countdown: React.FC<CountdownTimerProps> = ({ timestamp, className }) => {
  const [countdown, setCountdown] = useState<Countdown>({
    days: 0,
    hours: 0,
    minutes: 0,
  })

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now: Moment = moment()
      const targetTime: Moment = moment(timestamp)
      const duration: moment.Duration = moment.duration(targetTime.diff(now))

      const days: number = Math.floor(duration.asDays())
      const hours: number = duration.hours()
      const minutes: number = duration.minutes()

      setCountdown({
        days,
        hours,
        minutes,
      })

      if (now >= targetTime) {
        clearInterval(intervalId)
        setCountdown({
          days: 0,
          hours: 0,
          minutes: 0,
        })
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [timestamp])

  return (
    <div className={cn(className)}>
      {countdown.days}d {countdown.hours}h {countdown.minutes}m
    </div>
  )
}

export default Countdown
