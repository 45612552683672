import { getSuperRareBazaar } from "@/contracts/superrarebazaar"
import { Erc721TokenExtended } from "./base"
import { Maybe, NftAuction, User } from "@/graphql/generated/apollo/graphql"
import { CHAIN_ID } from "@/constants/environment"

/*
Artwork Card States
    - Running Auction:
        - endTime > 0 && endTime > currentTime
    - Upcoming Auction:
        - startTime > currentTime
    - Reserve Auction:
        - reservePrice > 0 && startTime == 0
    - Last Sale Price:
        - most_recent_sales
            - accepted_bid (aka accepted_offer)
            - market_sold (sale)
            - auction_ended
*/

interface useArtworkReturn {
  isAuction: undefined | Maybe<NftAuction>
  auction: undefined | Maybe<NftAuction>
  auctionState: string | undefined
  isRunningAuction: boolean | undefined
  isUpcomingAuction: boolean | undefined
  isReserveAuction: boolean | null | undefined
  owner: Maybe<User> | undefined
  isUnsettledAuction: boolean | undefined
}

const useArtwork = (artwork: Erc721TokenExtended): useArtworkReturn => {
  let isRunningAuction
  if (artwork.nft_auctions && artwork.nft_auctions?.length > 0) {
    isRunningAuction =
      artwork.nft_auctions[0]?.auction_state === "RUNNING_AUCTION"
  }

  let isUpcomingAuction
  if (artwork.nft_auctions && artwork.nft_auctions?.length > 0) {
    isUpcomingAuction =
      artwork.nft_auctions[0]?.auction_state === "PENDING_AUCTION"
  }

  let auctionState
  if (artwork.nft_auctions && artwork.nft_auctions?.length > 0) {
    auctionState = artwork.nft_auctions[0]?.auction_state
  }

  let isReserveAuction
  if (artwork.nft_auctions && artwork.nft_auctions.length > 0) {
    const auction = artwork.nft_auctions[0]
    isReserveAuction =
      auction && auction.reserve_price > 0 && !auction.starting_time
  }

  let isAuction
  if (artwork.nft_auctions && artwork.nft_auctions?.length > 0) {
    isAuction = artwork.nft_auctions[0]
  }

  let owner = artwork?.erc721_owner?.owner
  if (
    artwork?.erc721_owner?.owner_address.toLowerCase() ===
      getSuperRareBazaar(CHAIN_ID).toLowerCase() &&
    artwork?.nft_auctions?.[0]?.auction_creator
  ) {
    owner = artwork?.nft_auctions?.[0]?.auction_creator
  }

  let isUnsettledAuction
  if (
    artwork.nft_auctions &&
    artwork.nft_auctions?.length > 0 &&
    artwork.nft_auctions[0]?.auction_state === "UNSETTLED_AUCTION"
  ) {
    isUnsettledAuction =
      artwork.nft_auctions[0]?.auction_state === "UNSETTLED_AUCTION"
  }
  return {
    isAuction,
    auction: isAuction,
    auctionState,
    isRunningAuction,
    isUpcomingAuction,
    isReserveAuction,
    owner,
    isUnsettledAuction,
  }
}

export default useArtwork
