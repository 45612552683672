//import { IMGIX_SRC, IMGIX_KEY } from "@/constants/environment"
import ImgixClient from "@imgix/js-core"

/* todo: make sure is removed before prod */
const imgixClient = new ImgixClient({
  domain: "pixura.imgix.net",
  secureURLToken: "DnnyFYUFXWEWC3YE",
})

export const generateImgixUrl = (
  imageUri: string,
  params: Record<string, any>
): string => {
  if (!imageUri) {
    return ""
  }
  return imgixClient.buildURL(imageUri, params)
}
