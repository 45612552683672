import { create } from "zustand"

type Favorites = {
  isWaitingRemoval: number[]
  setIsWaitingRemoval: (isWaitingRemoval: number[]) => void
}

export const useFavoritesStore = create<Favorites>((set) => ({
  isWaitingRemoval: [],
  setIsWaitingRemoval: (isWaitingRemoval: number[]) =>
    set({ isWaitingRemoval }),
}))
